export const TRANSLATIONS_DE = {
  "occasion": "anlassfall",
  "You don’t have permissions to take a photo": "Sie haben keine Erlaubnis, ein Foto zu machen",
  "No internet connection": "Keine Internetverbindung",
  "Something went wrong": "Etwas ist schief gelaufen",
  "No services available": "Keine Dienste verfügbar",
  "Password must be at least 8 characters": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Groß- und einen Kleinbuchstaben, ein Symbol, eine Ziffer und keine Leerzeichen enthalten.",
  "Service registration is closed": "Die Anmeldung zum Dienst ist geschlossen.",
  "New password does not meet security requirements": "Neues Passwort entspricht nicht den Sicherheitsanforderungen",
  "You can't remove this temporal item": "Sie können dieses zeitliche Element nicht entfernen",
  "add occasion": "anlass hinzufügen",
  "Occasion": "Anlassfall",
  "Optional": "Optional",
  "Flight reopened": "Flug wiedereröffnet",
  "This field is mandatory": "Dieses Feld ist obligatorisch",
  "success": "Erfolg",
  "Flight registration is closed": "Die Fluganmeldung ist geschlossen.",
  "validating": "validierung",
  "reopen flight": "flug wiedereröffnen",
  "close the flight": "den Flug beenden",
  "finalization": "fertigstellung",
  "approved": "genehmigt",
  "failed": "gescheitert",
  "validation": "validierung",
  "Photo registration": "Fotoanmeldung",
  "In order to close this flight you need to provide": "Um diesen Flug abzuschließen, müssen Sie die erforderlichen Angaben machen.",
  "Photo types required": "Fotoarten erforderlich",
  "or": "oder",
  "Something wrong": "Etwas stimmt nicht",
  "Receive job vacancies": "Erhalten Sie offene Stellen",
  "Job vacancy": "Stellenausschreibung",
  "Go to flights": "Zu den Flügen",
  "Page not found": "Seite nicht gefunden",
  "Please click here to proceed to flights list": "Bitte klicken Sie hier, um zur Flugliste zu gelangen",
  "Your session has expired. Please sign in again.": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
  "Timestamps type": "Typ der Zeitstempel",
  "You were signed out automatically": "Sie wurden automatisch abgemeldet. Das Token ist abgelaufen. Sie können sich mit Ihren Anmeldedaten erneut anmelden",
  "Are you sure you want to sign out?": "Sind Sie sicher, dass Sie sich abmelden wollen?",
  "is signed in": "ist eingetragen in",
  "Sign in": "Eintragen",
  "Registration closed": "Anmeldung geschlossen",
  "Close registration": "Registrierung abschließen",
  "Dummy Type": "Dummy Typ",
  "Aircraft Damage": "Schäden am Luftfahrzeug",
  "No types here yet": "Noch keine Typen hier",
  "Select type": "Typ auswählen",
  "Please briefly describe where you found the item": "Bitte beschreiben Sie kurz, wo Sie den Gegenstand gefunden haben. Geben Sie Raum oder Zeile und Reihe an, falls vorhanden",
  "type": "Typ",
  "Describe location": "Beschreiben Sie den Standort",
  "Please pick on scheme": "Bitte wählen Sie ein Schema",
  "Cones": "Kegel",
  "Chocks": "Drosseln",
  "Cannot set a future time.": "Es kann keine zukünftige Zeit eingestellt werden.",
  "This section is used to deal with visible damage to the airplane": "Dieser Abschnitt dient der Behandlung von sichtbaren Schäden am Flugzeug. Bitte dokumentieren Sie jedes auffällige Problem, bevor Sie einen Service durchführen",
  "Mark location": "Standort markieren",
  "The end time must be later than the start time.": "Die Endzeit muss nach der Startzeit liegen.",
  "Future time is not allowed": "Zukünftige Zeit ist nicht erlaubt",
  "No timestamps configured for this flight type": "Keine Zeitstempel für diese Flugart konfiguriert",
  "Set time": "Zeit einstellen",
  "Confirm remove?": "Bestätigen Sie das Entfernen?",
  "Change": "Ändern Sie",
  "Change password?": "Passwort ändern?",
  "My profile": "Mein Profil",
  "Destination": "Reiseziel",
  "Origin": "Herkunft",
  "filter group not found": "Filtergruppe nicht gefunden",
  "Date should not be before start date": "Das Datum sollte nicht vor dem Startdatum liegen",
  "Could not save data, please try again later": "Daten konnten nicht gespeichert werden, bitte versuchen Sie es später noch einmal",
  "The current password you've entered is incorrect": "Das aktuelle Passwort, das Sie eingegeben haben, ist falsch. Bitte überprüfen Sie es noch einmal und versuchen Sie es erneut.",
  "no user data": "keine Benutzerdaten",
  "Helpdesk": "Helpdesk",
  "SENT": "SENT",
  "DENIED": "ABGELEHNT",
  "DECLINED": "ABGELEHNT",
  "CONFIRMED": "BESTÄTIGT",
  "ACCEPTED": "AKZEPTIERT",
  "Set to now": "Auf jetzt einstellent",
  "Set": "Satz",
  "Write your comment here, then press the green check mark": "Schreiben Sie hier Ihren Kommentar und drücken Sie dann das grüne Häkchen",
  "write new comment": "neuen Kommentar schreiben",
  "Short remark": "Kurze Bemerkung",
  "Filter group has not been updated": "Die Filtergruppe wurde nicht aktualisiert",
  "Save service registrations problem": "Problem beim Speichern von Dienstregistrierungen",
  "and/or": "und/oder",
  "Input a brief description here": "Geben Sie hier eine kurze Beschreibung ein",
  "Right Tail Wing": "Rechter Heckflügel",
  "Left Tail Wing": "Linker Heckflügel",
  "Tail": "Cola",
  "Body": "Körper",
  "Neck": "Cuello",
  "Nose": "Nase",
  "Right Engine": "Rechter Motor",
  "Left Engine": "Linker Motor",
  "Right Wing": "Rechter Flügel",
  "Left Wing": "Linker Flügel",
  "next": "nächste",
  "Lost item": "Verlorener Gegenstand",
  "Damage": "Schaden",
  "Filter group": "Gruppe filtern",
  "Archived": "Archiviert",
  "Message archived": "Nachricht archiviert",
  "Message unarchived": "Nachricht nicht archiviert",
  "No": "Nein",
  "Yes": "Ja",
  "to": "zu",
  "Date range": "Datumsbereich",
  "Telex messages": "Telex-Nachrichten",
  "No flight details information": "Keine Informationen zu Flugdetails",
  "No messages yet": "Noch keine Meldungen",
  "messages yet": "keine Meldungen",
  "Flight not found": "Flug nicht gefunden",
  "from": "von",
  "From": "Von",
  "Until": "Bis",
  "Search": "Suche",
  "registration complete": "Registrierung abgeschlossen",
  "Flight number/registration": "Flugnummer/Registrierung",
  "Attachment": "Anhang",
  "Airline": "Fluggesellschaft",
  "Username": "Benutzername",
  "Password": "Passwort",
  "Log in": "Einloggen",
  "Open file": "Datei öffnen",
  "Unavailable": "Nicht verfügbar",
  "Read & Sign": "Lesen&Unterschreiben",
  "Available": "Verfügbar",
  "Sign for understanding": "Zeichen für Verständnis",
  "You can check your APPI code on Sunrise portal.": "Sie können Ihren APPI-Code im Sunrise-Portal überprüfen.",
  "We strongly recommend saving it in your smartphone phonebook": "Wir empfehlen dringend, ihn in Ihrem Smartphone-Telefonbuch zu speichern, damit Sie den APPI-Code immer zur Hand haben. Um den Code zu speichern, gehen Sie zu Ihrem Sunrise-Profil und scannen Sie den QR-Code mit Ihrem Smartphone.",
  "The APPI code is required when resetting your Aviapartner": "Der APPI-Code wird benötigt, wenn Sie das Passwort Ihres Aviapartner-Kontos in Sunrise oder über den Aviapartner-Helpdesk zurücksetzen. Sie können Ihren APPI-Code immer in Ihrem Profil in Sunrise finden.",
  "Extra shift request confirmed": "Zusatzschichtanforderung bestätigt",
  "This request is not valid anymore": "Diese Anfrage ist nicht mehr gültig",
  "This message is marked as urgent": "Diese Nachricht ist als dringend gekennzeichnet",
  "A new password should be different than the current one": "Ein neues Passwort sollte anders sein als das aktuelle",
  "Your password has expired. Please compose a new password and log in again.": "Ihr Passwort ist abgelaufen. Bitte erstellen Sie ein neues Passwort und melden Sie sich erneut an.",
  "Your password and confirmation password do not match": "Ihr Kennwort und das Bestätigungskennwort stimmen nicht überein.",
  "Your password must contain at least eight characters": "Ihr Passwort muss mindestens acht Zeichen, mindestens eine Zahl und sowohl Klein- als auch Großbuchstaben enthalten.",
  "The username or password you've entered is incorrect": "Der eingegebene Benutzername oder das Passwort ist falsch. Bitte überprüfen Sie es noch einmal und versuchen Sie es erneut.",
  "Log out": "Abmelden",
  "Change password": "Passwort ändern",
  "Confirm new password": "Neues Passwort bestätigen",
  "New password": "Neues Passwort",
  "Current password": "Aktuelles Passwort",
  "is logged in": "eingeloggt ist",
  "Proceed to login": "Weiter zur Anmeldung",
  "Your password has been changed successfully!": "Ihr Passwort wurde erfolgreich geändert!",
  "Pick if its a damaged part or lost item": "Wählen Sie, ob es sich um ein beschädigtes Teil oder einen verlorenen Gegenstand handelt",
  "Pin on schema": "Pin auf Schema",
  "Specify where did you found the item": "Geben Sie an, wo Sie den Artikel gefunden haben",
  "Include nearby area": "Nahbereich einbeziehen",
  "Add other location": "Anderen Standort hinzufügen",
  "Close": "Schließen",
  "Add row": "Zeile hinzufügen",
  "Add line": "Zeile hinzufügen",
  "Skip this step": "Überspringen Sie diesen Schritt",
  "Add description": "Beschreibung hinzufügen",
  "Add location where item was found": "Ort hinzufügen, an dem der gegenstand gefunden wurde",
  "Add storage location": "Lagerort hinzufügen",
  "Briefly describe where the item will be stored for picking up.": "Beschreiben Sie kurz, wo der Gegenstand zur Abholung gelagert wird.",
  "Take a photo": "Ein Foto machen",
  "Messages": "Mitteilungen",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed":
  "FirebaseFehler: Ein Dokument kann nicht geschrieben werden, da es die maximal zulässige Größe überschreitet",
  "Photos": "Fotos",
  "area": "bereich",
  "Remove": "Entfernen",
  "Are you sure you want to remove photo?": "Sind Sie sicher, dass Sie das Foto entfernen möchten?",
  "Comment": "Kommentar",
  "Included nearby area": "Inklusive Nahbereich",
  "Tap on area where damaged part is located": "Tippen Sie auf die Stelle, an der sich das beschädigte Teil befindet",
  "Conditional services": "Bedingte Leistungen",
  'SYSTEM SETTINGS': 'Systemeinstellungen',
  'Flights': 'Flüge',
  'Weather': 'Wetter',
  'Language': 'Sprache',
  'Receive Extra shift requests': 'Zusätzliche Schichtanfragen erhalten',
  'Default station': 'Standardstation',
  'Profile data wasn\'t updated!': 'Profildaten wurden nicht aktualisiert!',
  'Station': 'Bahnhof',
  'Sign out': 'Ausloggen',
  'Cancel': 'Stornieren',
  'Stations': 'Stationen',
  'Profile': 'Profil',
  'Next 5 days': 'Nächste 5 Tage',
  'Today': 'Heute',
  'TODAY': 'HEUTE',
  'Humidity': 'Feuchtigkeit',
  'Wind': 'Wind',
  'Precipitation': 'Niederschlag',
  'Pressure': 'Druck',
  'Clouds': 'Wolken',
  'Visibility': 'Sichtweite',
  'Sunrise': 'Sonnenaufgang',
  'Flight details': 'Flugdaten',
  'General info': 'Allgemeine Information',
  'Parking': 'Parken',
  'Runway': 'Runway',
  'Gate': 'Tor',
  'Aircraft type': 'Flugzeugtyp',
  'Operation flight number': 'Betriebsflugnummer',
  'Seats': 'Sitze',
  'Seat': 'Sitze',
  'Flight type': 'Flugtyp',
  'Registration': 'Anmeldung',
  'Call sign': 'Rufzeichen',
  'Crew signature': 'UNTERSCHRIFT CREW',
  'Extra services': 'Zusätzliche Dienstleistungen',
  'extra service': 'zusätzlicher Service',
  'Comments': 'Bemerkungen',
  'Service registration': 'Serviceregistrierung',
  "Message type": "TYPE DE MESSAGE",
  "All": "Alle",
  "APPLY": "ANWENDUNG",
  "Filter": "Filter",
  "Signed": "Unterschrieben",
  "Internal communication": "Interne Kommunikation",
  "Extra shift request": "Anfrage für Sonderschichten",
  "Extra Shift Request": "Anfrage für Sonderschichten",
  "Please, sign below": "Bitte, unterschreiben Sie unten",
  "SAVE": "Speichern",
  "save": "speichern",
  "CLEAR": "löschen",
  "Service info saved": "Service-Info gespeichert",
  "Time registration": "Keine Anleitung gefunden",
  "No instructions found": "No se encontraron instrucciones",
  "Instructions": "Anleitungen",
  "NOW": "JETZT",
  "Sunset": "Sonnenuntergang",
  "The app has been updated": "Die App wurde aktualisiert",
  "End time should not be before start time": "Die Endzeit sollte nicht vor der Startzeit liegen.",
  "Start Time": "Startzeit",
  "Start": "Start",
  "End Time": "Endzeit",
  "End": "End",
  "Tomorrow": "Morgen",
  "Yesterday": "Gestern",
  "No photo available": "Kein Foto vorhanden",
  "Show Photo": "Foto anzeigen",
  "Walkaround Arrival Checklist": "Walkaround - Ankunfts - Checkliste",
  "Inspections": "Inspections",
  "Walkaround Pre-Departure Checklist": "Walkaround-Checkliste vor der Abteilung",
  "Completed": "Abgeschlosse",
  "Not completed": "Nicht abgeschlossen",
  "Visual checks must be performed BEFORE GSE positioning": "Vor der GSE - Positionierung müssen visuelle Überprüfungen durchgeführt werden",
  "Visual checks must be performed AFTER GSE removal": "Visuelle Überprüfungen müssen nach der GSE - Entfernung durchgeführt werden",
  "Report any irregularities found upon arrival": "Melden Sie alle Unregelmäßigkeiten bei der Ankunft",
  "Report any other irregularities": "Andere Unregelmäßigkeiten melden",
  "If damage is found, mark it on the sketch": "Wenn Schäden gefunden werden, markieren Sie ihn auf der Skizze",
  "Checks on easily visible parts of the aircraft from the ground": "Überprüft leicht sichtbare Teile des Flugzeugs vom Boden:",
  "Please make sure you attached photos of irregularities": "Bitte stellen Sie sicher,dass Sie Fotos von Unregelmäßigkeiten angeschlossen haben",
  "Add description if there is a irregularity!": "Beschreibung hinzufügen, wenn es eine Unregelmäßigkeit gibt!",
  "Sign form below to complete it": "Zeichenformular unten, um es auszufüllen:",
  "This form is signed": "Dieses Formular ist signiert",
  "Reopen": "Wieder öffnen",
  "Add note": "Notiz hinzufügen...",
  "Hold Inspection on Arrival": "Inspektion Laderäume (Holds) Ankunfts",
  "Hold Inspection on Departure": "Inspektion Laderäume (Holds) Abfahrt",
  "Flight information registration": "Registrierung von Fluginformationen",
  "Arrival MVT sent successfully": "Arrival MVT erfolgreich gesendet",
  "Arrival MVT sending": "Arrival MVT wird gesendet",
  "Arrival MVT not sent yet": "Arrival MVT nicht gesendet",
  "Cannot send Arrival MVT": "Arrival MVT kann nicht gesendet werden. Adressen (SITA und/oder Email) sind nicht in Sunrise konfiguriert.",
  "SEND ARRIVAL MVT": "Arrival MVT senden",
  "SEND CORRECTION ARRIVAL MVT": "Correction Arrival MVT senden",
  "In order to send Arrival MVT you need to provide mandatory details": "Zum Senden des Arrival MVT müssen Pflichtfelder ausgefüllt werden",
  "Send the Arrival MVT message to recipients:": "Arrival MVT an Empfänger senden:",
  "the MVT will be sent": "Nach dem Klick auf 'CONFIRM' wird das MVT gesendet. Es kann erneut gesendet werden, wenn es Änderungen in ALDT und/oder AIBT gab.",
  "the correction MVT will be sent": "Nach dem Klick auf 'CONFIRM' wird das Correction MVT gesendet. Es kann erneut gesendet werden, wenn es Änderungen in ALDT und/oder AIBT gab.",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Ungültige ALDT/AIBT Werte oder Arrival MVT mit diesen ALDT und AIBT Werten existiert bereits",
  "Cannot create MVT message: missing flight information": "MVT Message kann nicht erstellt werden: fehlende Fluginformationen",
  "MVT History": "MVT History",
  "Timeline": "Timeline",
  "No photos here yet": "noch keine Fotos vorhanden",
  "Safety Ramp Checklist": "Safety Ramp Checklist",
  "required to close the flight": "erforderlich, um den Flug zu schließen",
  "Forms": "Formulare",
  "Message state": "Nachrichtenstatus",
  "Locked application": "Anwendung blockiert",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "Um den Kalender zu öffnen, müssen zuerst die Read&Sign Nachrichten gelesen und bestätigt werden.",
  "Number of unread R&S:": "Anzahl der ungelesenen Read & Signs:",
  "Go to Unread R&S": "Gehe zu ungelesenen Read & Signs",
  "The shift is already confirmed to": "Dieser Dienst wurde bereits für",
  "employees": "Mitarbeitenden vergeben",
  "Name Surname": "Name Nachname",
  "Start anti-icing": "Anti-Icing Start",
  "Stop anti-icing": "Anti-Icing Ende",
  "Deicing": "De-Icing",
  "Anti-icing": "Anti-Icing",
  "Type": "Typ",
  "Product type": "Produkt Typ",
  "Product name": "Produkt Name",
  "Mix": "Mischung",
  "Temperature (C°)": "Temperatur (C°)",
  "Quantity (liter)": "Menge (Liter)",
  "Add deicing/anti-icing": "De-Icing/Anti-Icing hinzufügen",
  "Agent’s name:": "Name des Agents:",
  "Agent’s signature:": "Unterschrift des Agents:",
  "Client’s signature:": "Unterschrift des Kunden:",
  "Load Verification Cross-Check Form": "Crosscheck Beladung",
  "You are offline": "You are offline.",
  "The planning shown below might not be up-to-date": "The planning shown below might not be up-to-date.",
};
